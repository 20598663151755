/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://ekvbtx4gxzhxzm7fkyxawoqeiu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "quicksight",
            "endpoint": "https://87zky559lg.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:ece6a27f-5202-4b94-b5d2-ce135a5f065f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mF6OgbAh6",
    "aws_user_pools_web_client_id": "15e89dbss24rtd44f15t4n0fvg",
    "oauth": {},
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
